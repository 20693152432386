import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import SectionHeroBanner from "../components/Common/SectionHeroBanner";
import MainTitle from "components/Common/MainTitle";
import ServiceCardLayout2 from "components/Service/ServiceCardLayout2";
import ServiceCardLayout1 from "components/Service/ServiceCard";
import CardImg1 from '../assets/images/Workout.png'
import CardImg2 from '../assets/images/Workout1.png'
import CardImg3 from '../assets/images/Workout3.png'
import CardImg4 from '../assets/images/Workout4.png'
import DrillingService from '../assets/images/DrillingService.png'
import GeneratorSets from '../assets/images/GeneratorState.png'
import DuelFuel from '../assets/images/DueFuelEngine.png'
import CrudOil from '../assets/images/CrudOil.png'
import SAFETY from '../assets/images/IWCF.png'
class Services extends Component {
 
  render() {
    const CardDataSection1 = [{
      CardImg: CardImg1 , 
      Title : 'Workover Rig Services',
      content: ' We are one of the leading service providers for Workover and Completion Services to various Oil and Gas companies in India.',
      slug:'workover-rig-Services'
   } , 
   {
      CardImg: CardImg2 , 
      Title : 'Operation & Maintenance Services',
      content: 'We provide operation and maintenance services for Offshore Drilling Rigs, Onshore Drilling and Workover rigs, Oil and Gas installations, Sucker Rod Pump Units etc.',
      slug:'operation-maintenance-services'
  } ,
   {
      CardImg: CardImg3 , 
      Title : 'Oil and Gas Exploration',
      content: 'South Asia Consultancy, primarily an Oil and Gas service provider, has diversified in the oil and gas Exploration and...',
      slug:'oil-and-gas-exploration'
  } , 
   {
      CardImg: CardImg4 , 
      Title : 'Nitrogen Pumping Unit Services',
      content: 'We offer Nitrogen Pumping Unit services for various oilfield purposes. NPU is a hydraulic heat recovery Liquid Nitrogen Pump and vaporizer system.',
      slug:'nitrogen-pumping-unit-services'
  } 
  
  
  ]
    const CardDataSection2 = [{
      CardImg: GeneratorSets , 
      Title : 'Generator Sets ',
      content: ' We provide wide range of Diesel Generators on rental basis with excellent services. We offer 05KVA to 25KVA, 35KVA to 200 KVA and 250KVA, 320KVA, 380KVA, 500KVA, 600KVA, 750KVA, ',
      slug:'generator-sets'
   } , 
   {
      CardImg: DuelFuel , 
      Title : 'Duel Fuel Engines Services',
      content: 'We provide operation and maintenance services for Offshore Drilling Rigs, Onshore Drilling and Workover rigs, Oil and Gas installations, Sucker Rod Pump Units etc.',
      slug:'duel-fuel-engines-services'
  } ,
   {
      CardImg: DrillingService , 
      Title : 'Drilling Services',
      content: 'South Asia Consultancy, primarily an Oil and Gas service provider, has diversified in the oil and gas Exploration and...',
      slug:'drilling-services'
  } , 
   {
      CardImg: CrudOil , 
      Title : 'Crude Oil transportation Services ',
      content: 'We offer Nitrogen Pumping Unit services for various oilfield purposes. NPU is a hydraulic heat recovery Liquid Nitrogen Pump and vaporizer system.',
      slug:'crude-oil-transportation-services'
  } 
  
  
  ]

    return (
      <Fragment>
        <MetaTags>
          <title>SouthAsiaConsultancy | Services</title>
          <meta name="description" content="Organic Food React JS Template." />
        </MetaTags>
        <LayoutOne>
          <SectionHeroBanner
            title={"Services"}
            className={"sectionServicesBg"}
          />
		  <div className="container py-5">

		  <MainTitle text={'Services Offered'}  className={'text-center'} position={'center'} width={'171px'}/>
		  <p className="fw600 fs32px lnh-48 "> 
		 Professional Estimaters  Services Pvt. Ltd. (SACPL) is a company that specializes in providing a range of drilling and marine-related services to clients in the oil and gas industry. Some of the services offered byProfessional Estimaters  Services include:
		  </p>
		  </div>
      <div className='d-flex justify-content-center'>
 {/* <MainTitle text={'Our Expertise'}/> */}
        </div>
      <ServiceCardLayout1 CardData = {CardDataSection1}/>
      <ServiceCardLayout2/>
      <ServiceCardLayout1 CardData = {CardDataSection2}/>
     <div className="container">
  <div className="row">
 <div className="col-md-8">
 <Link to={`/service/iWCF-iADC-and-other-safety-training`}>
 <MainTitle text={'IWCF-IADC and other safety Training'}/>
  </Link>
    <h1 className="fs24px fw500" >
    Well Control and Safety Trainings
    </h1>
    <p className="fs18px lnh-34 fw500">
    South Asia Energy Services Pvt ltd, a vertical of SAC group of companies, is a premier training institute providing basic to advance level training of Safety and Well Control to Oil and Gas Field Personnel.
    </p>
    <span className='color-primary read-more-underline'>
            <Link to={`/service/iWCF-iADC-and-other-safety-training`}>
            Read More
            </Link>
        </span>
 </div>
 <div className="col-md-4">
<img src={SAFETY} alt="safety" className="img-fluid"/>
 </div>
  </div>

   
     </div>
        </LayoutOne>
      </Fragment>
    );
  }
}

export default Services;
