import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import SectionHero from "../components/Home/SectionHero";
import SectionServiceSlider from "../components/Home/SectionServiceSlider";

const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Professional Estimaters</title>
        <meta
          name="description"
          content="Organic Food React JS Template."
        />
      </MetaTags>
      <LayoutOne>
     <SectionHero/>
     <SectionServiceSlider/>
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
